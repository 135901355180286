<template>
	<div id="facility-detail-div">
		<el-row :gutter="20">
			<el-col :span="12">
				<el-row :gutter="20">
					<el-col :span="12">
						<el-card shadow="always" style="min-height: 200px;">
							<div class="card_div1">
								<div class="circle_class clolor409EFF">
								</div>
								<div style="display: inline-block;height: 12px;">设备信息</div>
							</div>
							<div class="card_div2"><span>设备名称: </span>{{form.name}}</div>
							<div class="card_div2"><span>设备UID: </span>{{form.id}}</div>
							<div class="card_div2"><span>时间: </span>{{form.time}}</div>
							<div class="card_div2"><span>时间: </span>{{form.time}}</div>
						</el-card>
					</el-col>
					<el-col :span="12">
						<el-card shadow="always" style="min-height: 200px;">
							<div class="card_div1">
								<div class="circle_class clolor409EFF">
								</div>
								<div style="display: inline-block;height: 12px;">设备数据</div>
							</div>
							<div class="card_div3 m-top-10">
								<div class="circle_class clolorFF6C60">
								</div>
								<span>水位</span> 1
							</div>
							<div class="card_div3">
								<div class="circle_class clolorDFAB01">
								</div>
								<span>风速</span> 2
							</div>
							<div class="card_div3">
								<div class="circle_class clolor7FB900">
								</div>
								<span>雨量</span> 3
							</div>
						</el-card>
					</el-col>
				</el-row>
				<el-row :gutter="20" style="margin: 20px 0 0 0;">
					<el-col :span="24">
						<el-card shadow="always" style="min-height: 540px;">
							<gps-data style="height: 540px;width:100%;"></gps-data>
						</el-card>
					</el-col>
				</el-row>
			</el-col>
			<el-col :span="12">
				<el-row :gutter="20">
					<el-col :span="24">
						<el-card shadow="always" style="height: 250px;">
							<div class="card_div1">
								<div class="circle_class clolor409EFF">
								</div>
								<div style="display: inline-block;height: 12px;">今日数据</div>
							</div>
							<div style="position: absolute; height: 100%;width: calc(100% - 20px);"
								class="el-table el-table--enable-row-hover el-table--medium">
								<div id="eId" ref="commandstats" style="height: 100%" />
							</div>
						</el-card>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="24">
						<el-card shadow="always" style="margin-top: 50px;">
							<img style="height: 460px;width: 100%;"
								src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg0.bjsoyo.com%2Fupfiles%2F20100203%2F20100203171936722.jpg&refer=http%3A%2F%2Fimg0.bjsoyo.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1637579628&t=a4ce423682b809956ec60e457582a86a" />
						</el-card>
					</el-col>
				</el-row>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import GpsData from '@/views/public/gps_data';

	export default {
		components: {
			GpsData,
		},
		data() {
			return {
				form: {
					id: '',
					name: 'sssdd',
					time: '2021-10-20 16:36:00',
				},
				queryParams: {
					pageNum: 1,
					pageSize: 10,
				},
				// 统计命令信息
				commandstats: null,
			};
		},
		created() {
			this.form.id = this.$route.query.id;
			this.$nextTick(() => {
				this.getList();
			})
		},
		watch: {

		},
		methods: {
			getList() {
				// this.commandstats = this.echarts.init(this.$refs.commandstats, "macarons");
				this.commandstats = this.echarts.init(document.getElementById("eId"), "macarons");
				var option = this.getOption("", "", "", "");
				this.commandstats.setOption(option);
			},
			getOption(legendData, xAxisData, series1Data, series2Data) {
				var option = {
					backgroundColor: '#080b30',
					title: {
						text: '水位',
						textStyle: {
							align: 'center',
							color: '#fff',
							fontSize: 20,
						},
						top: '5%',
						left: 'center',
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							lineStyle: {
								color: {
									type: 'linear',
									x: 0,
									y: 0,
									x2: 0,
									y2: 1,
									colorStops: [{
										offset: 0,
										color: 'rgba(0, 255, 233,0)'
									}, {
										offset: 0.5,
										color: 'rgba(255, 255, 255,1)',
									}, {
										offset: 1,
										color: 'rgba(0, 255, 233,0)'
									}],
									global: false
								}
							},
						},
					},
					grid: {
						top: '15%',
						left: '5%',
						right: '5%',
						bottom: '15%',
						// containLabel: true
					},
					xAxis: [{
						type: 'category',
						axisLine: {
							show: true
						},
						splitArea: {
							// show: true,
							color: '#f00',
							lineStyle: {
								color: '#f00'
							},
						},
						axisLabel: {
							color: '#fff'
						},
						splitLine: {
							show: false
						},
						boundaryGap: false,
						data: ['A', 'B', 'C', 'D', 'E', 'F'],

					}],

					yAxis: [{
						type: 'value',
						min: 0,
						// max: 140,
						splitNumber: 4,
						splitLine: {
							show: true,
							lineStyle: {
								color: 'rgba(255,255,255,0.1)'
							}
						},
						axisLine: {
							show: false,
						},
						axisLabel: {
							show: false,
							margin: 20,
							textStyle: {
								color: '#d1e6eb',

							},
						},
						axisTick: {
							show: false,
						},
					}],
					series: [{
						name: '注册总量',
						type: 'line',
						// smooth: true, //是否平滑
						showAllSymbol: true,
						// symbol: 'image://./static/images/guang-circle.png',
						symbol: 'circle',
						symbolSize: 25,
						lineStyle: {
							normal: {
								color: "#00ca95",
								shadowColor: 'rgba(0, 0, 0, .3)',
								shadowBlur: 0,
								shadowOffsetY: 5,
								shadowOffsetX: 5,
							},
						},
						label: {
							show: true,
							position: 'top',
							textStyle: {
								color: '#00ca95',
							}
						},

						itemStyle: {
							color: "#00ca95",
							borderColor: "#fff",
							borderWidth: 3,
							shadowColor: 'rgba(0, 0, 0, .3)',
							shadowBlur: 0,
							shadowOffsetY: 2,
							shadowOffsetX: 2,
						},
						tooltip: {
							show: false
						},
						areaStyle: {
							normal: {
								color: new this.echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: 'rgba(0,202,149,0.3)'
									},
									{
										offset: 1,
										color: 'rgba(0,202,149,0)'
									}
								], false),
								shadowColor: 'rgba(0,202,149, 0.9)',
								shadowBlur: 20
							}
						},
						data: [281.55, 398.35, 214.02, 179.55, 289.57, 356.14, ],
					}, ]
				};
				return option;
			},
		}
	};
</script>

<style lang="scss">
	#facility-detail-div {
		.el-card__body {
			padding: 0 0 0 0 !important;
		}

		.m-top-10 {
			margin-top: 10px;
		}

		.card_div1 {
			background-color: #EAEEF4;
			padding: 10px;
			vertical-align: middle;
		}

		.card_div2 {
			padding: 10px;
		}

		.card_div3 {
			padding: 15px 0px 15px 10px;
		}

		.card_col {
			padding: 10px;
		}

		.circle_class {
			display: inline-block;
			width: 12px;
			height: 12px;
			border-radius: 10px;
			margin-right: 10px;
		}

		.clolor409EFF {
			background-color: #409EFF;
		}

		.clolorFF6C60 {
			background-color: #FF6C60;
		}

		.clolorDFAB01 {
			background-color: #DFAB01;
		}

		.clolor7FB900 {
			background-color: #7FB900;
		}
	}
</style>
